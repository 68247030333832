<template>
    <div>
        <AboutNav />
        <main class="col-lg-10 col-md-9 col-sm-9 left_gutter main fill">
            <h1>About CDI</h1>
            <h4>CDI Story - The O'Brien Family Business</h4>

            <img class="img-responsive img_content_right" :src="`${$cdiVars.BASE_URL}/images/v3/obrien_family_photo.png`">

            <p>The story of CDI is a classic business success story. In April 1994 entrepreneur, Pat O'Brien, started Crystal Distribution Inc. in Crystal, MN.  The business began as a manufacturer's rep selling dampers, louvers and ductwork accessories to contractors in the Minneapolis area. It didn't take long for Pat to know that it was hard to find manufacturers who followed through on deadlines.</p>

            <p>After missing deadline after deadline Pat decided to make a major change to his business plan and become a manufacturing company.</p>

            <p>CDI custom curb adapters differentiated itself from other manufacturing companies by meeting deadlines - period.  The original facility was a small 5,000 sq ft building shared with two other companies in order to help with expenses. Pat's days were spent calling on customers and his nights we spent building what he sold. Some days he sold so much product that he had to recruit friends and family to come and work throughout the night to complete the jobs on time.</p>

            <p>1998 arrived with CDI experiencing solid success and rapid growth. Its reputation as a company built upon strong values and the ability to ship on time continued to grow as did its market share which had spread across much of the Midwest. Pat's wife, Bobbi Jo, joined the company that same year and it was then they decided to grow a family business.</p>

            <p>By 2002 they had hired Pat's father, Jim O'Brien, to be the first outside sales person and Pat's high school friend, Mark Greenwood, to be the first engineer for CDI. Both had been part of the crew that pulled the all-nighters in the beginning.</p>

            <p>With growing customer demand for pricing catalogs and drawings, the CDI website was created. The industry's first online system was launched where customers could get quotes, drawings and email confirmations when an order was received and another one when the product was shipped. The industry loved this tool and their business exploded!</p>

            <p>Currently CDI has 5 plants strategically located across the USA in Charlotte, Minneapolis, Dallas, Seattle and Phoenix providing 2-3 day maximum shipping for their customers. With the acquisition of several high tech machines, many more employees, the use of the company's innovative website and online tools, CDI has become a nationwide curb manufacturer.  Pat and Bobbi Jo's roles have evolved as more team members have joined CDI with Pat in the President and Visionary role and Bobbi Jo as COO and Implementer.</p>

            <p>Today CDI leads the curb adapter industry with the fastest lead times and the most curbs manufactured annually. Thirty years later, the values that CDI was built upon are present in each facet of the company and the strong desire to completely satisfy all of its customers remains at the core of CDI's mission.  They've always understood that without the continued support of its customers, the O'Briens would not have had the ability to turn their idea into reality.</p>
        </main>
    </div>
</template>

<script>
import AboutNav from '@/components/content/AboutNav.vue'
export default {
    name: "About",
    components: {
        AboutNav,
    },
    created() {
        this.$appStore.setTitle(['About CDI']);
    },
}
</script>

<style scoped>

</style>